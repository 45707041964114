<template>
  <div>
 
    <div class="">
      <div class="StickyBar flex flex-wrap gap-2 justify-between mb-4">
        <h2 class="text-2xl">{{t('global.stats')}}</h2>
        <div>
          <date-picker confirm="true" v-model="dateRange" :placeholder="t(`global.choose_data_range`)" range></date-picker>
          <!--- <button @click="exportStats" :disabled="loading" class="Btn Btn--Save md:ml-4">{{t('actions.export_stats')}}</button> -->
        </div>
      </div>
      <div class="mb-8">
        
        <div class="grid sm:grid-cols-2 gap-3 mb-4">
          <div class="Hero Box">
            <img src="../../../images/party2.png" class="Hero__Img2">
            <div class="Hero__Caption">
              <span class="Hero__Icon">
                <svg height="16" width="16" class="icon-mood-happy-outline fill-current ciao">
                  <use xlink:href="#icon-mood-happy-outline"></use>
                </svg>
              </span>
              <h1 class="text-2xl mb-3 font-bold capitalize">{{t('pages.home.welcome')}} {{user.first_name}}</h1>
              <p class="text-xl mb-3">{{t('pages.stats.find_out_teams_progress')}}</p>
              <hr class="Hero__Separator mb-3">
              <div>
                <span class="pr-2 inline-block"><strong>{{t('global.user.p')}}:</strong> {{ info.users }}</span>
                <span><strong>{{t('global.course.p')}}:</strong> {{ info.courses }}</span>
              </div>
            </div>
          </div>
          <div class="Box Box--Clear">
            <BgTitle title="Continuous learning index" icon="book-reference"></BgTitle>
            <p class="mb-4">{{t('pages.stats.monitor_teams_approach')}} 
                <strong content="Strumento che misura l'approccio degli utenti alla formazione continua all'interno della vostra azienda. Questo indice offre una panoramica completa sia quantitativa che qualitativa dell'interesse e dell'impegno degli utenti nell'aggiornare e ampliare le proprie competenze nel tempo. Si basa sul monitoraggio delle attività di formazione, valutando il loro completamento e la frequenza con cui vengono svolte." v-tippy class="underline">
                  Continuous Learning Index
                </strong>
            </p>
            <div v-if="loading.cli">
              <p class="text-warning pt-4 text-center"><strong>Stiamo calcolando il tuo CLI...</strong></p>
            </div>
            <div class="ProgressGradient mt-16" v-else>
              <div class="ProgressGradient__Bg">
                <div class="ProgressGradient__Bar" :style="{left: `${cli}%`}">
                  <span>{{ Math.round(cli) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid sm:grid-cols-3 gap-3 mb-4">
          <div class="Box Box--Clear text-center">
           <p class="font-bold text-xl mb-4">{{t('pages.stats.how_long_teams_formed')}}</p>
            <div v-if="loading.graphs" class="LoadingBox  LoadingBox--Small text-center">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
            <div class="font-bold" v-else>
              <p class="text-4xl">{{ ssToHH(globalActivities.total_duration) }}</p>
            </div>
          </div>
          <div class="Box Box--Clear col-span-2">
            <BgTitle :title="t('pages.stats.team_favourite_courses')" icon="calendar"></BgTitle>
            <span class="Tag" v-for="course in favouriteCourses" :key="course.id">{{ course }}</span>
          </div>
        </div>
      
        <div class="grid sm:grid-cols-2 gap-3">
          <div class="Box Box--Clear ">
            <p class="font-bold text-xl mb-4">{{ t('pages.stats.team_activity', { rangeActive: rangeActive ? t('global.selected_period') : t('global.last_12_months') }) }}?</p>

            <div v-if="loading.graphs" class="LoadingBox  LoadingBox--Small text-center">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
            <div v-else>
              <column-chart :data="convertSeconds(globalActivities.months_by_duration)" :colors="[`${colorPrimary}`]"></column-chart>
              <p class="mt-3  text-sm text-center">{{ t('pages.stats.minutes_studied_month')}}</p>
            </div>
          </div>
          <div class="Box Box--Clear">
            <p class="font-bold text-xl mb-4">{{t('pages.stats.areas_team_developing')}}</p>
            <pie-chart v-if="areas > 0"  :data="enrollsByArea" legend="bottom" ></pie-chart>
            <div v-else class="pt-4 text-center">
              <p>{{t('pages.stats.no_area_in_platform')}}</p>
              <p>{{t('pages.stats.add_and_associate_with_course')}}</p>
            </div>
          </div>
          <div class="Box Box--Clear ">
            <p class="font-bold text-xl mb-4">{{t('pages.stats.days_team_prefer_study')}}</p>
            <div v-if="loading.graphs" class="LoadingBox  LoadingBox--Small text-center">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
            <div v-else>
              <column-chart :data="convertSeconds(globalActivities.days_by_duration)" :colors="[`${colorPrimary}`]" ></column-chart>
              <p class="mt-3 text-sm text-center">{{t('pages.stats.minutes_study_day_week')}}</p>
            </div>
            
          </div>
          <div class="Box Box--Clear ">
            <p class="font-bold text-xl mb-4">{{t('pages.stats.time_team_prefer_study')}}</p>
            <div v-if="loading.graphs" class="LoadingBox  LoadingBox--Small text-center">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
            <div v-else>
              <column-chart :data="convertSeconds(globalActivities.hours_by_duration)" :colors="[`${colorPrimary}`]"></column-chart>
              <p class="mt-3 text-sm text-center">{{t('pages.stats.minutes_study_time_slot')}}</p>
            </div>
          </div>
         
        </div>
      
     
      </div>

      <div class="mb-8">
        <div class="flex flex-wrap gap-2 justify-between mb-4">
          <h2 class="text-2xl">{{t('global.stats')}} {{ t('global.users') }}</h2>
          <div class="flex">
            <input  v-model="searchUsers" type="text" :placeholder="t(`global.search_users`)"  class="flex-1 mr-2" v-on:keyup.enter="getUsersFromSearch"   />
            <button @click="getUsersFromSearch" class="Btn Btn--Save">{{t('actions.search')}}</button>
          </div>
        </div>
       
        <div class="Table__Container">
          <table class="Table table-auto"  >
            <thead>
              <tr >
                <th class="text-left">
                  <span class="font-medium flex gap-1 items-center text-left">
                    <strong>{{t('global.users')}}</strong>
                  </span>
                </th>
                <th class="text-left ">
                  <button :class="[{active: userSortBy === 'duration'}, 'flex gap-1 items-center text-left']" @click="sortUsers('duration')" >
                    <strong>{{t('global.total_hours')}}</strong>
                    <span>{{ userSortOrder === 'desc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
                <th class="text-left">
                  <span :class="'flex gap-1 items-center text-left font-medium'" >
                    <strong>{{t('global.courses_completed')}}</strong>
                  </span>
                </th>
                <th class="">
                  <button :class="[{active: userSortBy === 'last_activity'}, 'flex gap-1 items-center text-left']" @click="sortUsers('last_activity')" >
                    <strong>{{t('global.last_activity')}} </strong>
                    <span>{{ userSortOrder === 'desc' ? '↓' : '↑' }}</span>
                  </button>
              </th>
              </tr>
            </thead>
            <tbody :class="['List', {loading: loading.list}]">
              <div v-if="loading.list" class="LoadingBox mt-4 text-center">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
              <div v-else-if="error != ''" class="Box Box--Clear">
                <p class="text-danger">{{error}}</p>
              </div>
              <div v-else-if="enrollsByCourses.length == 0">
                <p class="text-left p-4">{{t('global.no_activities')}}</p>
              </div>
              <template v-else>
                <tr v-for="(user, i) in  users" :key="`course-${i}`">
                  <td class="Table__UserName" ><a :href="`/admin/users/${user.id}`">{{user.first_name}} {{user.last_name}}</a></td>
                  <td>{{ssToHH(user.duration)}}</td>
                  <td>{{user.completed_courses}}</td>
                  <td>{{user.last_activity}}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
       
        <div class="text-center mt-4">
            <paginate
              v-model="page"
              :page-count="pagy.pages"
              :click-handler="pageCallback"
              :prev-text="'<'"
              :next-text="'>'"
              :container-class="'PaginationList'">
            </paginate>
        </div>
      </div>
      
      
      <div class="mb-8">
        <div class="flex flex-wrap gap-2 justify-between mb-4">
          <h2 class="text-2xl">{{t('pages.stats.courses')}}</h2>
          <div class="flex">
            <input  v-model="search" type="text" :placeholder="t(`pages.courses.search_courses`)"  class="flex-1 mr-2" v-on:keyup.enter="getCoursesFromSearch"   />
            <button @click="getCoursesFromSearch" class="Btn Btn--Save">{{t('actions.search')}}</button>
          </div>
        </div>
       
        <div class="Table__Container">
          <table class="Table table-auto"  >
            <thead>
              <tr>
                <th class="Table__Courses text-left">{{t('global.courses.p')}}</th>
                <th class="text-left">
                  <button :class="[{active: activeSort === 'enrolls'}, 'flex gap-1 items-center text-left']" @click="sort('enrolls')">
                    <strong>{{t('pages.stats.enrolled_users')}}</strong>
                    <span>{{ toSortBy === 'asc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
                <th class="text-left ">
                  <button :class="[{active: activeSort === 'started'}, 'flex gap-1 items-center text-left']" @click="sort('started')" >
                    <strong> {{t('pages.stats.started_users')}}</strong>
                    <span>{{ toSortBy === 'asc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
                <th class="text-left">
                  <button :class="[{active: activeSort === 'completed'}, 'flex gap-1 items-center text-left']" @click="sort('completed')" >
                    <strong>{{t('pages.stats.completed_users')}}</strong>
                    <span>{{ toSortBy === 'asc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
                <th class="">
                  <button :class="[{active: activeSort === 'perc_completion'}, 'flex gap-1 items-center text-left']" @click="sort('perc_completion')" >
                    <strong>{{t('pages.stats.perc_media')}} </strong>
                    <span>{{ toSortBy === 'asc' ? '↓' : '↑' }}</span>
                  </button>
              </th>
              </tr>
            </thead>
            <tbody :class="['List', {loading: loading.list}]">
              <div v-if="loading.list" class="LoadingBox mt-4 text-center">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
              <div v-else-if="error != ''" class="Box Box--Clear">
                <p class="text-danger">{{error}}</p>
              </div>
              <div v-else-if="enrollsByCourses.length == 0">
                <p class="text-left p-4">{{t('global.no_activities')}}</p>
              </div>
              <template v-else>
                <tr v-for="(course, i) in  pageOfCourses" :key="`course-${i}`">
                  <td ><a :href="course.url">{{course.name}}</a></td>
                  <td>{{course.enrolls}}</td>
                  <td>{{course.started}}</td>
                  <td>{{course.completed}}</td>
                  <td>{{course.perc_completion}}%</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
       
        <div class="text-center mt-4">
          <jw-pagination :items="enrollsByCourses" @changePage="onChangePageCourses" :labels="customLabels" class="PaginationList"></jw-pagination>
        </div>
      </div>
    
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import api from '../../../api'
import * as XLSX from 'xlsx-js-style';
import DatePicker from 'vue2-datepicker';
import '../../commons/bgTitle.vue'
import BgTitle from '../../commons/bgTitle.vue';


export default {
  props: {
   user: Object,
   totDuration: Number,
   colorPrimary: String,
   info: Object,
   areas: Number
  },
  data(){
    return {
      enrollsByCourses: [],
      enrollsByArea: [],
      users: [],
      page: 1,
      pageUsers: 1,
      pagy: {},
      loading: {
        list: true,
        cli: true,
        graphs: true
      },
      errGraphs: '',
      error: false,
      pageOfCourses: [],
      toSortBy: 'asc',
      activeSort: 'enrolls',
      userSortOrder: 'desc',
      userSortBy: 'duration',
      search: '',
      searchUsers: '',
      dateRange: [],
      general: {},
      globalActivities: {},
      cli: 0,
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      }
    }
  },
  components: {
    DatePicker,
    BgTitle,
    BgTitle
},

  mounted() {
    this.getEnrollsByCourses()
    this.getEnrollsbyArea()
    this.getGeneralInfo()
    this.getGlobalActivities()
    this.getUserStats()
    this.getCli()
  },


  computed: {

    areasData() {
      return [['marketing', 10], ['sales', 20], ['management', 30], ['design', 40], ['development', 50]]
    },

    filteredCourses() {
      return this.enrollsByCourses.map(el => [el.name, el.enrolls])
    },

    favouriteCourses() {
      return this.enrollsByCourses.map(el => el.name).slice(0, 6)
    },

    chartData(){
      return this.filteredCourses.slice(0, 10)
    },

    rangeActive() {
      return this.dateRange.length > 0 && this.dateRange[0] != null && this.dateRange[1] != null
    },



    coursesForExport() {
      return this.enrollsByCourses.map(course => (
        {
          name: course.name,
          enrolls: course.enrolls,
          started: course.starte,
          completed: course.completed,
          perc_completion: course.perc_completion
        }
      ))
    },
    
    generalInfo() {
      return [{
        users:  this.general.users,
        started: this.general.started,
        completed: this.general.completed_courses
      }]
    }

  },

  watch: {
    dateRange() {
      this.getEnrollsByCourses()
      this.getGeneralInfo()
      this.getGlobalActivities()
    }
  },
  methods: {

    getCli() {

      api.Stats.getCli()
        .then( res => {
          this.cli = res
          this.loading.cli = false
        }).catch(err => {
          console.log(err)
          this.loading.cli = false
        })
    },

    ssToHH(totSecs) {
        let totalSeconds = totSecs || 0
        const hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        const minutes = Math.floor(totalSeconds / 60);
        return `${hours}h:${minutes}m `
    },

    convertSeconds(result) {
      if (!result) return {}
      return Object.keys(result).reduce((acc, key) => {
        acc[key] = parseFloat((result[key] / 60).toFixed(0))
        return acc
      }, {})      
     
    },

    getCoursesFromSearch(){
      this.page = 1
      this.getEnrollsByCourses()
    },

    getUsersFromSearch() {
      this.page = 1
      this.getUserStats()
    },
 
    getEnrollsByCourses() {
      api.Stats.getEnrollByCourses({
        page: this.page,
        search: this.search, 
        start_date: this.dateRange[0],
        end_date: this.dateRange[1]
      })
      .then( res => {
        this.enrollsByCourses = res.enrolls_by_courses;
        this.loading.list = false
      }).catch(err => {
        this.err = err
        this.loading.list = false
      })
    },

    getUserStats() {
      api.Stats.getUserStats({
        page: this.page,
        search: this.searchUsers, 
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        order: this.userSortOrder,
        sort: this.userSortBy
      })
        .then( res => {
          this.users = res.users
          this.pagy = res.pagy
        }).catch(err => {
          this.err = err
        })
    },

     getGeneralInfo() {
      api.Stats.getGeneralInfo({
        start_date: this.dateRange[0],
        end_date: this.dateRange[1]
      })
        .then( res => {
          this.general = res
        }).catch(err => {
          this.err = err
        })
    },

    getGlobalActivities() {
      api.Stats.getGlobalActivities({
      start_date: this.dateRange[0],
      end_date: this.dateRange[1]
    })
      .then( res => {
        this.globalActivities = res
        this.loading.graphs = false
      }).catch(err => {
        this.errGraphs = err
        this.loading.graphs = false
      })
    },


    onChangePageCourses(pageOfCourses) {
      // update page of items
      this.pageOfCourses = pageOfCourses;
    },

    pageCallback(pageNum){
      if (pageNum != undefined) {
        this.page = pageNum
      }
      if (!!this.search) {
        this.getUsersFromSearch()
      } else {
         this.getUserStats()
      }
    },

    sortUsers(value) {
      this.userSortOrder = this.userSortOrder === 'asc' ? 'desc' : 'asc'
      this.userSortBy = value
      this.getUserStats()
    },


    sort(value, controller) {
      console.log(this.activeSort, value, this.toSortBy)
      if  (this.activeSort !== value) this.toSortBy == 'desc'
      this.activeSort = value
      this.enrollsByCourses.sort((a, b) =>  this.toSortBy === 'asc' ? ( a[value] - b[value]) :  (b[value] - a[value]) );
      this.toSortBy = this.toSortBy === 'asc' ? 'desc' : 'asc'
    },

    exportStats() {
        const  wb = XLSX.utils.book_new()

        const sheets = [
          {
            heading: [ [
              { v: "Utenti totali", t: "s", s: { font: { bold: true } } },
              { v: "Corsi iniziati", t: "s", s:{ font: {  bold: true } } },
              { v: "Corsi completati", t: "s", s: { font: {  bold: true } } }
            ]],
            ws: XLSX.utils.json_to_sheet([]),
            arr: this.generalInfo,
            name:  "Info generali"
          },
          {
            heading: [ [
              { v: 'Corsi', t: "s", s: { font: { bold: true } } },
              { v: 'Utenti iscritti', t: "s", s:{ font: {  bold: true } } },
              { v: 'Utenti che hanno iniziato', t: "s", s: { font: {  bold: true } } },
              { v: 'Utenti che hanno completato', t: "s", s:{ font: {  bold: true } } },
              { v: 'Media di completamento', t: "s", s:{ font: {  bold: true } } },
            ]],
            ws: XLSX.utils.json_to_sheet([]),
            arr: this.coursesForExport,
            name: "Corsi"
          }
        ]

        sheets.forEach(el => {
          XLSX.utils.sheet_add_aoa(el.ws, el.heading);
          XLSX.utils.sheet_add_json(el.ws, el.arr, { origin: 'A2', skipHeader: true });
          XLSX.utils.book_append_sheet(wb, el.ws, el.name)
        })

        XLSX.writeFile(wb, `Report_General.xlsx`)
        
    },

    getEnrollsbyArea() {
      console.log('getEnrollsbyArea')
      api.Stats.getEnrollsByArea()
        .then( res => {
          this.enrollsByArea = res
        }).catch(err => {
          this.err = err
        })
    },

   
   

  }
}
</script>

<style lang="scss">
  .List.loading {
    position: relative;
    height: 150px;
    .LoadingBox {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  }

  .Table {
    &__Courses {
      width: 30%;
    }
    &__UserName {
      min-width: 220px
    }
    button {
      span {
        display: none;
      }
      &.active {
        span {
          display: block;
        }
      }
    }
  }
</style>