<template>
  <div class="Users">

    <div class="Tabs flex flex-wrap gap-3 justify-between items-center mb-8">
      <div class="Tabs__Nav gap-3 sm:gap-0">
        <button @click="selectTab('students', 'students')" :class="{selected: selectedTab ===  'students'}">{{t('global.users')}}</button>
        <button @click="selectTab('coaches', 'coaches')" :class="{selected: selectedTab ===  'coaches'}" v-if="coachingEnabled">{{t('global.coaches')}}</button>
        <button @click="selectTab('inspectors', 'inspectors')" :class="{selected: selectedTab ===  'inspectors'}" v-if="canSeeTab('inspector') && inspectorEnabled" >{{t('global.inspectors')}}</button>
        <button @click="selectTab('admins', 'admins')" :class="{selected: selectedTab ===  'admins'}" v-if="canSeeTab('admin')">{{t('global.admins')}}</button>
        <button @click="selectTab('super_admins', 'super_admins')" :class="{selected: selectedTab === 'super_admins'}"  v-if="canSeeTab('superAdmin')">{{t('global.super_admins')}}</button>
        <button @click="selectTab('lacerba_admins', 'lacerba_admins')"  :class="{selected: selectedTab === 'lacerba_admins'}" v-if="isLacerbaAdmin">{{t('global.lacerba_admins')}}</button>
      </div>
      <button v-if="reportsEnabled" class="Btn Btn--Save" :disabled="exportingUsers" @click="exportUsers">{{t('actions.export_users')}}</button>
      <button v-if="currentUser.admin_role != 'inspector'" @click="userInviteOpen = !userInviteOpen" :data-open="userInviteOpen" class="Btn Btn--Text Btn--Collapse">
       {{t('pages.users.invite_platform')}}
       <svg height="20" width="20" class="fill-current"> 
          <use v-if="!userInviteOpen" xlink:href="#icon-cheveron-down"></use>
          <use v-else xlink:href="#icon-cheveron-up"></use>
        </svg>
      </button>
    </div>

    <p v-if="exportingUsers" class="text-center mb-4">
      Export in corso. Riceverai una mail tra qualche minuto con il file csv.
    </p>
      
    <div v-if="userInviteOpen" class="bg-white  rounded-lg	 mb-8">
      <p class="mb-2 pt-6 pr-8 pl-8 text-grayScale-middle">{{t('pages.users.invite_platform_description')}} <a href="courses" class="Btn--Text">{{t('global.courses.p')}}</a>)</p>
      <div v-if="selectedTab === 'students'" class="sm:grid sm:grid-cols-2 sm:col-gap-4">
        <usersUpload type="user" :maxUsers="maxUsers" />
        <userInvite :currentUser="currentUser" userType="user"  :fiscalCodeLogin="fiscalCodeLogin" />  
      </div>
      <div v-else-if="selectedTab === 'coaches'" class="sm:grid sm:grid-cols-2 sm:col-gap-4">
        <userInvite :currentUser="currentUser" userType="coach" :fiscalCodeLogin="fiscalCodeLogin"/>  
      </div>
      <div v-else-if="selectedTab === 'inspectors'" class="sm:grid sm:grid-cols-2 sm:col-gap-4">
        <userInvite :currentUser="currentUser" userType="inspector" :fiscalCodeLogin="fiscalCodeLogin"/>  
      </div>
      <div v-else-if="selectedTab === 'admins'" class="sm:grid sm:grid-cols-2 sm:col-gap-4">
        <userInvite :currentUser="currentUser" userType="admin" :fiscalCodeLogin="fiscalCodeLogin" />
      </div>
      <div v-else-if="selectedTab === 'super_admins'" class="sm:grid sm:grid-cols-2 sm:col-gap-4">
        <userInvite v-if="currentUser.admin_role === 'superAdmin'" :currentUser="currentUser" userType="super_admin" :fiscalCodeLogin="fiscalCodeLogin" />
      </div>
      <div v-else-if="selectedTab === 'lacerba_admins'" class="sm:grid sm:grid-cols-2 sm:col-gap-4">
        <userInvite v-if="isLacerbaAdmin" :currentUser="currentUser" userType="lacerba_admin" :fiscalCodeLogin="fiscalCodeLogin" />
      </div>
    </div>


    <div class="Box Box--Clear mb-8">
      <div class="flex mb-4">
        <input  v-model="search" type="text" :placeholder="t('pages.users.search_user')"  class="flex-1 mr-2" v-on:keyup.enter="getUsersFromSearch(true)"   />
        <button @click="getUsersFromSearch(true)" class="Btn Btn--Save">{{t('actions.search')}}</button>
      </div>
      <div v-if="selectedTab === 'students' && registrationType === 3">
        <div class="Tabs">
          <div class="Tabs__Nav Tabs__Nav--Border mb-4">
            <button @click="selectTab('students', 'students')" :class="{selected: (selectedTab ===  'students' && userType === 'students' )}" >{{t('pages.users.approved')}}</button>
            <button @click="selectTab('students', 'to_be_approved')" :class="{selected: (selectedTab ===  'students' && userType === 'to_be_approved' )}" >{{t('pages.users.not_approved')}}</button>
          </div>
        </div>
      </div>
      <userList :users="allUsers" :loading="loading" :fiscalCodeLogin="fiscalCodeLogin" />
      <div class="text-center mt-2">
        <paginate
          v-model="page"
          :page-count="pagy.pages"
          :click-handler="pageCallback"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'PaginationList'">
        </paginate>
      </div>
    </div>


  </div>
  
</template>

<script>
import api from '../../../api'
import { mapState, mapActions } from 'vuex'
import usersUpload from '../../commons/usersUpload'
import userInvite from '../../commons/userInvite'
import userList from '../../commons/userList'

import { global as EventBus } from '../../../js/utils/eventBus'; 

export default {
  props: {
    currentUser: Object,
    paramsUserType: String,
    reportsEnabled: Boolean,
    coachingEnabled: Boolean,
    inspectorEnabled: Boolean,
    maxUsers: Number,
    fiscalCodeLogin: Boolean,
    registrationType: Number,
    isLacerbaAdmin: Boolean
  },
  data(){
    return {
      selectedTab: 'students',
      userType: 'students',
      page: 1,
      pagy: {},
      search: '',
      userInviteOpen: false,
      loading: false,
      exportingUsers: false
    }
  },
  mounted() {
   
    this.getUserType()
    // this.getAllUsers(this.users)
  },
  computed: {
    ...mapState({
      allUsers: state => state.user.allUsers
    }),
  },
  components: {
    usersUpload,
    userInvite,
    userList
  },
  methods: {
    ...mapActions({
      getAllUsers: 'getAllUsers'
    }),

    canSeeTab(requiredRole) {
      const rolesHierarchy = ['inspector', 'admin', 'superAdmin', 'lacerbaAdmin'];
      const currentUserRoleIndex = rolesHierarchy.indexOf(this.currentUser.admin_role);
      const requiredRoleIndex = rolesHierarchy.indexOf(requiredRole);
      return currentUserRoleIndex >= requiredRoleIndex;
    },


    getUsers(userType){
      this.loading = true
      api.User.getUsers(userType, this.page)
      .then(res => {
        this.getAllUsers(res.users)
        this.pagy = res.pagy
        this.loading = false;
      })
    },
    getUserType(){
      if (this.paramsUserType) {
        console.log(this.paramsUserType)
        this.userType = this.paramsUserType
      } 
      this.getUsers(this.userType)
      
    },
    selectTab(tab, userType){
      this.selectedTab = tab
      this.userType = userType
      this.page = 1
      this.getUsers(userType)
    },
    pageCallback(pageNum){
      if (pageNum != undefined) {
        this.page = pageNum
      }
      if (!!this.search) {
        this.getUsersFromSearch()
      } else {
         this.getUsers(this.userType)
      }
    },
    getUsersFromSearch(fromSearch){
      if (this.search.length > 0) {
      api.User.getUsersFromSearch({
        user_type: this.selectedTab,
        page: fromSearch ? 1 : this.page, 
        search: this.search
      } )
      .then( res => {
        console.log(res.users)
        this.getAllUsers(res.users)
        this.pagy = res.pagy
      })  
      } else {
        this.getUsers(this.userType)
      }
    },
    exportUsers(){
      api.User.exportUsers()
      .then( () => {
        this.exportingUsers = true
      })
    }
  }
}
</script>